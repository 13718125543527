import 'app/app';
import 'app/routes';
import 'interceptors';
import 'resources';
import 'ng-patch';

import 'common/api_notices';
import 'common/bootstrap-timepicker.min';
import 'common/calendar';
import 'common/datepickr';
import 'common/fix_rails_serializer';
import 'common/image_rotate';
import 'common/rainbow';
import 'common/map-quest-helper';
import 'common/market_segment';
import 'common/monday-picker-directive';
import 'common/polyline_utils';
import 'common/rectangle_hover';
import 'common/safe_apply';
import 'common/serializers';
import 'common/tech_report_service';
import 'common/time_formatter';
import 'common/timepicker';

import 'models/assignment_grid';
import 'models/day';

import 'controllers/assignments/bobs-ctrl.js';
import 'controllers/assignments/grid-ctrl.js';
import 'controllers/assignments/workspace_ctrl.js';
import 'controllers/bills/billing_overview_ctrl.js';
import 'controllers/clients/billable_pre_qs_ctrl.js';
import 'controllers/clients/billable_viz_techs_ctrl.js';
import 'controllers/clients/client_analytics_ctrl.js';
import 'controllers/employees/schedule_profile_ctrl.js';
import 'controllers/pre_qs/new-pre-q-ctrl.js';
import 'controllers/pre_qs/pre-q-lists-ctrl.js';
import 'controllers/pre_qs/pre-q-items-ctrl.js';
import 'controllers/viz_techs/new-viz-tech-ctrl.js';
import 'controllers/viz_techs/viz-tech-lists-ctrl.js';
import 'controllers/viz_techs/viz-tech-items-ctrl.js';
import 'controllers/routes/route_shared_ctrl.js';
import 'controllers/routes/edit_route_ctrl.js';
import 'controllers/routes/manage_route_ctrl.js';
import 'controllers/routes/map_ctrl.js';
import 'controllers/routes/new_route_ctrl.js';
import 'controllers/routes/plan_route_ctrl.js';
import 'controllers/routes/waypoints_ctrl.js';
import 'controllers/service-orders/abstract-service-orders-ctrl.js';
import 'controllers/service-orders/edit-service-orders-ctrl.js';
import 'controllers/service-orders/follow-up-service-orders-ctrl.js';
import 'controllers/service-orders/new-service-orders-ctrl.js';
import 'controllers/service-orders/service-order-ctrl.js';
import 'controllers/service-orders/service-order-items-ctrl.js';
import 'controllers/service-orders/service-order-lists-ctrl.js';
import 'controllers/service-orders/service-order-parts-order-ctrl.js';
import 'controllers/bills_ctrl.js';
import 'controllers/service_bills_ctrl.js';
import 'controllers/call_topics_ctrl.js';
import 'controllers/locations_ctrl.js';
import 'controllers/pictures_ctrl.js';
import 'controllers/sub-report-ctrl.js';
import 'controllers/tech-report-ctrl.js';
import 'controllers/todos_list_ctrl.js';
import 'controllers/variances_ctrl.js';

import 'filters/get_by_id';
import 'filters/max';

import 'services/scheduler_service';
import 'jquery-ui-1.10.3.custom.min';
